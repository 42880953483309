import React, { useEffect, useContext } from "react";
import "./track.css";

import { FiSettings, FiTruck, FiPackage } from "react-icons/fi";
import { BsCheck } from "react-icons/bs";
import { FaHome } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { TrackContext } from "../../context/TrackContext";
const TrackingDetail = ({ trackingDetail }) => {
  const { data, setData } = useContext(TrackContext);
  const track_detail = trackingDetail.data;
  const reversedArr = [...track_detail].reverse();
  //   console.log(track_detail[track_detail.length - 1].payload);
  console.log(trackingDetail);
  let status_title = "";
  for (let i = 0; i < reversedArr.length; i++) {
    if (reversedArr[i].payload.icon === "success") {
      status_title = reversedArr[i].payload.title;
      break;
    } else {
      status_title = "Processing";
    }
  }

  //   const deliveryState = [
  //     <FiSettings />,
  //     <FiTruck />,
  //     <FiPackage />,
  //     <FaHome />,
  //   ];
  return (
    <>
      <div className="my-20 mx-auto rounded-lg p-6 shadow-lg bg-gray-50 lg:w-8/12 md:w-9/12 sm:w-11/12 h-max">
        <div>
          <div className="mt-6 mb-10">
            {/* <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <h1 className="sub-title">
                <b>Client: </b>
              </h1>
            </div> */}
            <section style={{ display: "flex" }}>
              <div
                style={{
                  // display: "flex",
                  width: "100%",
                  // border: "1px solid blue",
                  justifyContent: "space-between",
                }}
              >
                <h1
                  className="sub-title"
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <b>Tracking Detail</b>
                  <br />
                </h1>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    // border: "1px solid red",

                    justifyContent: "space-between",
                  }}
                >
                  <p className="sub-tile client__name">
                    <span className="track__property">Client: </span>
                    <span className="prop__value">
                      {trackingDetail.client}{" "}
                    </span>
                  </p>
                  <p className="sub-tile">
                    <span className="track__property">Current Status:</span>
                    <span className="prop__value">
                      {trackingDetail.track_status}{" "}
                    </span>
                  </p>
                </div>
              </div>
              {/* =========================== */}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  // border: "1px solid blue",
                  justifyContent: "flex-end",
                  marginLeft: "auto",
                }}
              >
                <p className="sub-tile">
                  <span className="track__property">Carrier:</span>
                  <span className="prop__value">
                    &nbsp;
                    {trackingDetail.carrier}{" "}
                  </span>
                </p>
                <p className="sub-tile ">
                  <span className="track__property"> Tracking ID: </span> &nbsp;
                  <span className="tracking__id ">
                    {trackingDetail.trackingId}{" "}
                  </span>
                </p>
              </div>
            </section>
          </div>

          <div className="my-8">
            <div className="relative w-full">
              <hr className="border-4 border-gray-700 line_item up" />
              <hr
                className={`border-4 border-gray-700 line_item abs ${
                  track_detail[2].payload.icon === "success"
                    ? "step3"
                    : track_detail[1].payload.icon === "success"
                    ? "step2"
                    : track_detail[0].payload.icon === "success"
                    ? "step1"
                    : "step4"
                }`}
              />
              <div className="flex justify-between absolute -top-6 w-full">
                {
                  // deliveryState
                  //   .slice(0, track_detail.length)
                  track_detail.map((state, idx) => {
                    return (
                      <span
                        className={`p-4 rounded-full text-white lg:text-md md:text-md sm:text-sm  
                      ${
                        track_detail[idx].payload.icon === "success"
                          ? "bg-[#058AB3]  "
                          : "bg-gray-700  "
                      }
                      `}
                      >
                        {track_detail[idx].payload.icon === "success" ? (
                          <BsCheck size={20} />
                        ) : (
                          <TiCancel size={20} />
                        )}
                      </span>
                    );
                  })
                }
              </div>
            </div>
          </div>

          <div className="mt-16">
            {/* <div className="flex justify-start my-6">
              <span className={`rounded-full p-3 bg-[#058AB3]`}></span>
              <div className="lg:mx-16 sm:mx-6 text-sm">
                Item is been Processed at originating Hub
              </div>
            </div> */}
            {track_detail.map((item) => {
              if (item.payload.icon === "error") {
                return (
                  <div className="flex justify-start my-6">
                    <span className={`rounded-full p-3 bg-gray-700`}></span>

                    <div className="lg:mx-16 sm:mx-6 text-sm">
                      {item.payload.icon === "success"
                        ? item.payload.title
                        : ""}
                      <p
                        style={{ fontWeight: "bold" }}
                      >{`${item.payload.title} `}</p>
                      <p>
                        {`${item.payload?.msg ? item.payload.msg : ""} `}{" "}
                        {item.payload.sub_value ? item.payload.sub_value : ""}
                      </p>
                      <p>
                        <span style={{ fontWeight: "600", color: "#3A4046" }}>
                          {`${item.payload?.date ? item.payload.date : ""} `}{" "}
                        </span>
                        {item.payload.time ? item.payload.time : ""}
                      </p>
                    </div>
                  </div>
                );
              } else {
                let isObject =
                  typeof item.payload.sub_value === "object" &&
                  item.payload.sub_value !== null &&
                  !Array.isArray(item.payload.sub_value);
                return (
                  <div className="flex justify-start my-6">
                    <span className={`rounded-full p-3 bg-[#058AB3]  `}></span>

                    <div className="lg:mx-16 sm:mx-6 text-sm">
                      <p style={{ fontWeight: "bold" }}>{item.payload.title}</p>

                      <div style={{ display: "flex" }}>
                        <p>{`${item.payload.msg}   `}</p>
                        <p
                          style={{
                            textTransform: "capitalize",
                            marginLeft: "10px",
                          }}
                        >
                          {isObject
                            ? item.payload.sub_value.address
                            : item.payload.sub_value}
                        </p>
                      </div>
                      <p>
                        <span style={{ fontWeight: "600", color: "#3A4046" }}>
                          {`${item.payload?.date ? item.payload.date : ""} `}{" "}
                        </span>
                        {item.payload.time ? item.payload.time : ""}
                      </p>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackingDetail;
