import React from "react";
import "./loadingScreen.css";
function LoadingScreen() {
  return (
    <div>
      <section class="loading_wrapper">
        <div class="loading_card">
          <div class="loading_loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LoadingScreen;
