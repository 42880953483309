import React from 'react'

function Dashboard() {
  return (
    <>
        <h1>Login Successful</h1>
        <h3>Page is Under construction</h3>
    </>
  )
}

export default Dashboard;