import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import Footer from "../../components/footer";
import Navbar from "../../components/nav-bar";
import TrackingDetail from "../../components/tracking-detail";
import ErrorComponent from "../../components/error-component";
import { TrackContext } from "../../context/TrackContext";
import LoadingScreen from "./LoadingScreen";

const TrackItem = () => {
  const { tracking_code } = useParams();
  const [trackingDetail, setTrackingDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [isError, setIsError] = useState(false);
  const { data, setData } = useContext(TrackContext);
  // http://localhost/app/logistics/staff/transactions/trans_trac.php/?wayBillNum=JIB-1240821-NSK
  const handleTrackItem = () => {
    setLoading(true);
    let url = `https://www.ifexexpressnig.com/app/logistics/staff/transactions/trans_trac.php`;
    // let url = `http://localhost/app/logistics/staff/transactions/trans_trac.php`;
    if (tracking_code) {
      url += `?wayBillNum=${tracking_code}`;
      axios
        .get(url)
        .then((res) => {
          // console.log(res.data);
          setLoading(false);
          setIsError(false);

          // console.log(res.data.status);
          setStatus(res.data.status);
          setData(res.data);
          setTrackingDetail(res.data);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setIsError(true);
        });
    }
  };

  useEffect(() => {
    handleTrackItem();
  }, [tracking_code]);

  return (
    <>
      <Navbar />

      {/* <div className="w-full h-full bg-gray-50" style={{height: "100%"}}>
            </div> */}
      {loading && (
        <h1 className="sub-title">
          <LoadingScreen />
          {/* Loading ... */}
        </h1>
      )}
      {!loading && (
        <div className="lg:w-9/12 sm:w-10/12 mx-auto">
          {isError ? (
            <ErrorComponent />
          ) : (
            Object.keys(trackingDetail).length > 0 && (
              <TrackingDetail trackingDetail={trackingDetail} />
            )
          )}
        </div>
      )}
      <Footer />
    </>
  );
};

export default TrackItem;
