import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { authRoutes, privateRoutes } from "./routes";
import ProtectedRoutes, { UnProtectedRoutes } from "./routes/protected-route";
import { TrackContextProvider } from "./context/TrackContext";

function App() {
  const getRoutes = (routes) =>
    routes.map((route, indx) => {
      const Component = route.component;
      return (
        <Route
          path={route.path}
          key={indx}
          element={
            <TrackContextProvider>
              <UnProtectedRoutes>{Component}</UnProtectedRoutes>
            </TrackContextProvider>
          }
        />
      );
    });

  const getProtectedRoutes = (routes) =>
    routes.map((route, indx) => {
      const Component = route.component;
      return (
        <Route
          path={route.path}
          key={indx}
          element={<ProtectedRoutes>{Component}</ProtectedRoutes>}
        />
      );
    });

  return (
    <Router>
      <Routes>
        {getRoutes(authRoutes)}
        {getProtectedRoutes(privateRoutes)}
      </Routes>
    </Router>
  );
}

export default App;
