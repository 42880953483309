import Dashboard from "../admin/dashboard";

const privateRoutes = [
    {
        path: '/admin/dashboard',
        component: <Dashboard />
    },
    
]

export default privateRoutes;