import { createContext, useState } from "react";

export const TrackContext = createContext();

export function TrackContextProvider({ children }) {
  const [data, setData] = useState([]);
  return (
    <TrackContext.Provider
      value={{
        data,
        setData,
      }}
    >
      {children}
    </TrackContext.Provider>
  );
}
